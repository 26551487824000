<template>
  <b-row class="pb-2 pt-2">
    <b-col>
      <b-row>
        <b-col>
          <b-form-group
            label="Claim / File Number"
            label-for="claimNumber"
          >
            <b-form-input
              id="claimNumber"
              v-model="ClaimFileNo"
              placeholder=""
              name="claimNumber"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Type of Claim / File"
            label-for="auto"
          >
            <b-form-checkbox
              id="auto"
              v-model="Auto"
              name="auto"
              inline
            >
              Auto
            </b-form-checkbox>
            <b-form-checkbox
              id="wc"
              v-model="WC"
              name="wc"
              inline
            >
              W/C
            </b-form-checkbox>
            <b-form-checkbox
              id="liability"
              v-model="Liability"
              name="liability"
              inline
            >
              Liability
            </b-form-checkbox>
            <b-form-checkbox
              id="property"
              v-model="Property"
              name="property"
              inline
            >
              Property
            </b-form-checkbox>
            <b-form-checkbox
              id="disability"
              v-model="Disability"
              name="disability"
              inline
            >
              Disability
            </b-form-checkbox>
            <b-form-checkbox
              id="medicalMalpractice"
              v-model="MedicalMalpractice"
              name="medicalMalpractice"
              inline
            >
              Medical Malpractice
            </b-form-checkbox>
            <b-form-checkbox
              id="other"
              v-model="Other"
              name="other"
              inline
            >
              Other
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="9">
          <b-form-group
            label="Insured (Policy Holder)"
            label-for="insured"
          >
            <b-form-input
              id="insured"
              v-model="InsuredText"
              placeholder=""
              name="insured"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Insured Representative"
            label-for="insuredRepresentative"
          >
            <b-form-input
              id="insuredRepresentative"
              v-model="InsuredRepresentative"
              placeholder=""
              name="insuredRepresentative"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Insured Contact Information"
            label-for="InsuredContact"
          >
            <b-form-input
              id="InsuredContact"
              v-model="InsuredContact"
              placeholder=""
              name="InsuredContact"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Claim Handler"
            label-for="claimHandler"
          >
            <b-form-input
              id="claimHandler"
              v-model="ClaimHandler"
              placeholder=""
              name="claimHandler"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="pt-2">
          <b-form-checkbox
            id="contactedAccountManager"
            v-model="IsContactByAccountManager"
            name="contactedAccountManager"
          >
            Check here to be contacted by your Account Manager
          </b-form-checkbox>
        </b-col>
        <b-col class="pt-2">
          <b-form-checkbox
            id="preferPhysicalMedia"
            v-model="IsPreferPhysicalMedia"
            name="preferPhysicalMedia"
          >
            Check here if you prefer physical media
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-form-group
          label="Services"
          label-for="activityCheck"
          class="pl-1"
        >
          <b-form-checkbox
            id="activityCheck"
            v-model="ActivityCheck"
            name="activityCheck"
            inline
          >
            Activity Check
          </b-form-checkbox>
          <b-form-checkbox
            id="surveillance1Investigator"
            v-model="Surveillance1Investigator"
            name="surveillance1Investigator"
            inline
          >
            Surveillance - 1 Investigator
          </b-form-checkbox>
          <b-form-checkbox
            id="surveillance2Investigator"
            v-model="Surveillance2Investigator"
            name="surveillance2Investigator"
            inline
          >
            Surveillance - 2 Investigator
          </b-form-checkbox>
          <b-form-checkbox
            id="aliveAndWellnessCheck"
            v-model="AliveAndWellnessCheck"
            name="aliveAndWellnessCheck"
            inline
          >
            Alive and Wellness Check
          </b-form-checkbox>
          <b-form-checkbox
            id="unmannedSurveillanceUSP"
            v-model="UnmannedSurveillanceUSP"
            name="unmannedSurveillanceUSP"
            inline
          >
            Remote Controlled Surveillance (RCS)
          </b-form-checkbox>
          <b-form-checkbox
            id="netSweepSocialMediaResearch"
            v-model="NetSweepSocialMediaResearch"
            name="netSweepSocialMediaResearch"
            inline
          >
            NetSweep / Social Media Research
          </b-form-checkbox>
        </b-form-group>
        <b-col md="6">
          <b-form-group>
            <b-form-checkbox
              id="medicalCanvassing"
              v-model="MedicalCanvassing"
              name="medicalCanvassing"
              inline
            >
              Medical Canvassing
            </b-form-checkbox>
            <b-form-checkbox
              id="backgroundInvestigation"
              v-model="BackgroundInvestigation"
              name="backgroundInvestigation"
              inline
            >
              Background Investigation
            </b-form-checkbox>
            <b-form-checkbox
              id="claimsInvestigations"
              v-model="ClaimsInvestigations"
              name="claimsInvestigations"
              inline
            >
              Claims Investigations
            </b-form-checkbox>
            <b-form-checkbox
              id="sIUNonSurveillance"
              v-model="SIUNonSurveillance"
              name="sIUNonSurveillance"
              inline
            >
              SIU
            </b-form-checkbox>
            <b-form-checkbox
              id="servicesOther"
              v-model="ServicesOther"
              name="servicesOther"
              inline
            >
              Other
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <div
              class="collapse-textarea"
              :class="{expanded: !isExpandedOther}"
            >
              <textarea
                v-model="RequestedServicesOther"
                class="expandable-textarea"
                :class="[isExpandedOther ? 'not-expanded' : 'expanded']"
                contentEditable
              />
              <div @click="isExpandedOther = !isExpandedOther">
                <span
                  class="expand-arrow mt-n1"
                  :class="[isExpandedOther ? 'not-expanded' : 'expanded']"
                >&#187;</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="Budget Hours"
            label-for="budgetHours"
          >
            <b-form-input
              id="budgetHours"
              v-model="BudgetHours"
              type="number"
              placeholder=""
              name="budgetHours"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Budget $"
            label-for="budget"
          >
            <b-form-input
              id="budget"
              v-model="BudgetDollars"
              type="number"
              placeholder=""
              name="budget"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Due Date"
            label-for="caseDueDate"
          >
            <b-form-input
              id="caseDueDate"
              v-model="CaseDueDate"
              type="date"
              placeholder=""
              name="caseDueDate"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow
} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  data() {
    return {
      Assignment: {},
      ClaimFileNo: "",
      ClaimFileType: "",
      InsuredText: "",
      InsuredContact: "",
      InsuredRepresentative: "",
      ClaimHandler: "",
      IsContactByAccountManager: false,
      IsPreferPhysicalMedia: false,
      RequestedServices: [],
      Services: [],
      BudgetHours: 0,
      BudgetDollars: "",
      CaseDueDate: "",
      DatesToBeConducted: "",
      RequestedServicesOther: "",
      ReceivedSource: "",
      NetSweepFeasibility: null,
      USPFeasibility: null,

      isExpandedOther: false,

      ActivityCheck: false,
      Surveillance1Investigator: false,
      Surveillance2Investigator: false,
      AliveAndWellnessCheck: false,
      UnmannedSurveillanceUSP: false,
      NetSweepSocialMediaResearch: false,
      MedicalCanvassing: false,
      BackgroundInvestigation: false,
      ClaimsInvestigations: false,
      SIUNonSurveillance: false,
      ServicesOther: false,
      servicesOption: {
        ActivityCheck: {
          title: "Activity Check",
          value: "Activity Check",
        },
        Surveillance1Investigator: {
          title: "Surveillance - 1 Investigator",
          value: "Surveillance - 1 Investigator",
        },
        Surveillance2Investigator: {
          title: "Surveillance - 2 Investigator",
          value: "Surveillance - 2 Investigator",
        },
        AliveAndWellnessCheck: {
          title: "Alive and Wellness Check",
          value: "Alive and Wellness Check",
        },
        UnmannedSurveillanceUSP: {
          title: "Remote Controlled Surveillance (RCS)",
          value: "Remote Controlled Surveillance (RCS)",
        },
        NetSweepSocialMediaResearch: {
          title: "NetSweep / Social Media Research",
          value: "NetSweep / Social Media Research",
        },
        MedicalCanvassing: {
          title: "Medical Canvassing",
          value: "Medical Canvassing",
        },
        BackgroundInvestigation: {
          title: "Background Investigation",
          value: "Background Investigation",
        },
        ClaimsInvestigations: {
          title: "Claims Investigations",
          value: "Claims Investigations",
        },
        SIUNonSurveillance: {
          title: "SIU",
          value: "SIU",
        },
        ServicesOther: {
          title: "Other",
          value: "Other",
        }
      },

      Auto: false,
      WC: false,
      Liability: false,
      Property: false,
      Disability: false,
      MedicalMalpractice: false,
      Other: false,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "publicCase/getCase",
    }),
    getCaseData() {
      return this.getCase;
    }
  },
  watch: {
    getCaseData(val) {this.fillAssignment(val)},

    Auto(val) {this.setClaimFileType(val, "Auto")},
    WC(val) {this.setClaimFileType(val, "W/C")},
    Liability(val) {this.setClaimFileType(val, "Liability")},
    Property(val) {this.setClaimFileType(val, "Property")},
    Disability(val) {this.setClaimFileType(val, "Disability")},
    MedicalMalpractice(val) {this.setClaimFileType(val, "MedicalMalpractice")},
    Other(val) {this.setClaimFileType(val, "Other")},

    ActivityCheck(val) {this.ServicesChanges(val, "ActivityCheck")},
    Surveillance1Investigator(val) {this.ServicesChanges(val, "Surveillance1Investigator")},
    Surveillance2Investigator(val) {this.ServicesChanges(val, "Surveillance2Investigator")},
    AliveAndWellnessCheck(val) {this.ServicesChanges(val, "AliveAndWellnessCheck")},
    UnmannedSurveillanceUSP(val) {this.ServicesChanges(val, "UnmannedSurveillanceUSP")},
    NetSweepSocialMediaResearch(val) {this.ServicesChanges(val, "NetSweepSocialMediaResearch")},
    MedicalCanvassing(val) {this.ServicesChanges(val, "MedicalCanvassing")},
    BackgroundInvestigation(val) {this.ServicesChanges(val, "BackgroundInvestigation")},
    ClaimsInvestigations(val) {this.ServicesChanges(val, "ClaimsInvestigations")},
    SIUNonSurveillance(val) {this.ServicesChanges(val, "SIUNonSurveillance")},
    ServicesOther(val) {this.ServicesChanges(val, "ServicesOther")},

    NetSweepFeasibility(val) {this.Assignment = {...this.Assignment, NetSweepFeasibility: val}},
    USPFeasibility(val) {this.Assignment = {...this.Assignment, USPFeasibility: val}},
    ClaimFileNo(val) {this.Assignment = {...this.Assignment, ClaimFileNo: val}},
    ClaimFileType(val) {this.Assignment = {...this.Assignment, ClaimFileType: val}},
    InsuredText(val) {this.Assignment = {...this.Assignment, InsuredText: val}},
    InsuredContact(val) {this.Assignment = {...this.Assignment, InsuredContact: val}},
    InsuredRepresentative(val) {this.Assignment = {...this.Assignment, InsuredRepresentative: val}},
    ClaimHandler(val) {this.Assignment = {...this.Assignment, ClaimHandler: val}},
    IsPreferPhysicalMedia (val) {this.Assignment = {...this.Assignment, IsPreferPhysicalMedia : val}},
    IsContactByAccountManager (val) {this.Assignment = {...this.Assignment, IsContactByAccountManager : val}},
    ReceivedSource(val) {this.Assignment = {...this.Assignment, ReceivedSource: val}},
    BudgetHours(val) {this.Assignment = {...this.Assignment, BudgetHours: val}},
    BudgetDollars(val) {this.Assignment = {...this.Assignment, BudgetDollars: val}},
    CaseDueDate(val) {this.Assignment = {...this.Assignment, CaseDueDate: val}},
    DatesToBeConducted(val) {this.Assignment = {...this.Assignment, DatesToBeConducted: val}},

    RequestedServices(val) {this.Assignment = {...this.Assignment, RequestedServices: val}},
    RequestedServicesOther(val) {this.Assignment = {...this.Assignment, RequestedServicesOther: val}},

    Services(val) {
      const items = [];
      val.map(item => items.push(item.value))
      this.RequestedServices = items
    },
    Assignment() {
      this.$emit("changeAssignment", this.Assignment);
    }
  },
  created() {
    this.fillAssignment(this.getCaseData)
  },
  methods: {
    ServicesChanges(val, key) {
      if (val) {
        this.Services.push(this.servicesOption[key])
      } else {
        this.Services = this.Services.filter(i => i.title !== this.servicesOption[key].title)
      }
    },
    setClaimFileType(val, name) {
      if (val) {
        this[this.ClaimFileType === "W/C" ? "WC" : this.ClaimFileType] = false;
        this.ClaimFileType = name;
      } else if (name === this.ClaimFileType) {
        this.ClaimFileType = "";
      }
    },
    fillRequestedServices() {
      for(const key in this.servicesOption) {
        if (this.RequestedServices.filter(i => this.servicesOption[key].title === i).length > 0) {
          this[key] = true
        }
      }
    },
    fillAssignment(res) {
      if(res.ClaimFileType) {
        this[res.ClaimFileType === "W/C" ? "WC" : res.ClaimFileType] = true;
      }
      if (this.RequestedServices.length) {
        this.fillRequestedServices()
      }
      this.ClaimFileNo = res.ClaimFileNo;
      this.NetSweepFeasibility = res.NetSweepFeasibility;
      this.USPFeasibility = res.USPFeasibility;
      this.InsuredText = res.InsuredText;
      this.InsuredClientID = res.InsuredClientID;
      this.InsuredContact = res.InsuredContact;
      this.InsuredRepresentative = res.InsuredRepresentative;
      this.ClaimHandler = res.ClaimHandler;
      this.IsContactByAccountManager = res.IsContactByAccountManager;
      this.IsPreferPhysicalMedia = res.IsPreferPhysicalMedia;
      this.BudgetHours = Number(res.BudgetHours);
      this.BudgetRemainingHours = res.BudgetRemainingHours;
      this.BudgetDollars = res.BudgetDollars;
      this.CaseDueDate = res.CaseDueDate;
      this.DatesToBeConducted = res.DatesToBeConducted;
      this.RequestedServicesOther = res.RequestedServicesOther;
      this.RequestedServices = res.RequestedServices;
      this.ReceivedSource = res.ReceivedSource;
    }
  }

}
</script>

<style scoped>
</style>